@use 'src/styles/mediaValues' as screen
@use 'src/styles/functions' as func

.main-button
  display: inline-block
  min-width: func.rem(182)
  padding: 0 func.rem(20)
  box-sizing: border-box
  border-radius: func.rem(100)
  // min-height: func.rem(50)
  line-height: func.rem(48)
  font-size: func.rem(18)
  text-align: center
  cursor: pointer
  font-weight: normal
  transition: background-color .3s
  border: none
  &:not(:last-child)
    margin-right: 12px
  @media screen and (max-width: 768px)
    font-size: 16px
    margin-right: 0
    margin-left: 0
    padding: 8px 0
    line-height: 24px
  &.primary
    background: #2F80ED
    color: #fff
    @media screen and (max-width: 768px)
      margin: 0
    &:hover
      background: #2172DF
  &.secondary
    background: #989BA4
    color: #fff
    margin-right: 12px
    @media screen and (max-width: 768px)
      margin: 0
    &:hover
      background: #989BA4
  &.disabled
    pointer-events: none
    background: none
    border: 1px solid var(--disable-button-border)
    color: var(--disable-button-color)
    @media screen and (max-width: 768px)
      margin: 0
