html[data-theme='light'] {
  --main-bg: #F3F3F5;
  --content-block-bg: #fff;
  --main-color: #202020;
  --answers-block-description-color: #585858;
  --start-description-color: #5F6473;
  --delimiter-color: #BCC9D4;
  --secondary-color: #989BA4;
  --grey-with-blue-color: #589395;
  --unit-tree-main-color: #5F6473;
  --units-tree-children-background: #F3F3F5;
  --sna-tree-department-color: #696a70;
  --sna-tree-line-color: #C0C0C0;
  --input-error-color: #F44A5F;

  /*select questions*/
  --select-question-default-bg: #F7F7F7;
  --select-question-default-color: #5F6473;
  --select-question-delimer-color: #e0e0e0;

  --bonus-button-background: #F0F0F0;

  /*block36*/
  --hover-unit-button: var(--pale-grey);
  --rated-unit-color: #BCC9D4;
  --secondary-text-360-color: var(--primary-light-grey);

  /*main-buttons*/
  --disable-button-border: #909097;
  --disable-button-color: #909097;

  /*radio-block*/

  --radio-case-text: #5F6473;

  /* return-code-input */
  --return-code-input-border: var(--primary-light-grey);

  // ADMIN PANEL VARIABLES
  --admin-color-font-interactive-elements: #3C3F47;
  --admin-color-font-hover-interactive-elements: #000000;
  --admin-background-interactive-elements: #F3F3F5;
  --admin-hover-background-interactive-elements: #E3E4E6;

  // font colors
  --title-font: var(--primary-dark-grey);
  --font-active-color: var(--primary-dark-grey);
  --admin-hover-main-text: var(--primary-dark-grey);
  --link-in-text-block-color: var(--primary-dark-grey);

  // admin-buttons
  --admin-btn-bg: var(--pale-grey);
  --admin-btn-color: var(--pale-dark);
  --admin-btn-hover: #d0d0d0;
  --admin-btn-hover-color: var(--pale-dark);

  --light-grey-line-color: #E5E5E5;

  --hover-grey-text: #5c6068;
  --hover-content-block-bg: #f5f5f5;

  --admin-filter-projects-border: #d0d0d0;

  // outline
  --admin-outline-color: var(--primary-light-grey);
}