@use "src/styles/functions" as func
@use "src/styles/mediaValues" as screen
@import "~sanitize.css"
@import "src/styles/icons"
@import "src/../static/fonts/Roboto/stylesheet.css"
@import "src/styles/sizes"

html, body, #app
  height: 100%
body
  font-family: 'Roboto', sans-serif
  font-weight: normal
  margin: 0
  background-color: var(--main-bg)
  color: var(--main-color)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  user-select: auto

button, input, select, textarea, legend
  font-family: "Roboto", sans-serif
  font-weight: inherit
  font-style: inherit
  background: unset
  color: inherit
  border: unset
  &:hover
    cursor: pointer

button, select, label, img
  user-select: none

label
  &:hover
    cursor: pointer

img
  object-fit: contain
  display: block

@font-face
  font-family: icons
  font-display: swap
  src: url("/static/fonts/icons.woff") format("woff"), url("/static/fonts/icons.ttf") format("ttf")
  font-weight: 400
  font-style: normal

ul
  list-style: none
  margin: 0
  padding: 0

h1,
h2,
h3,
h4,
h5
  margin: 0
  padding: 0

h1
  font-size: func.rem(28)
  font-weight: 590
  line-height: 1.17

p
  margin: 0
  padding: 0

input
  padding: 0
  margin: 0
  font-family: 'Roboto', sans-serif

.btn-without-style
  background: none
  border: none
  outline: none
  cursor: pointer
  font-family: "Roboto", sans-serif
  @media screen and (max-width: screen.$large-phone-screen)
    padding: 0

.input-without-style
  background: none
  border: none
  outline: none
  text-decoration: none
  padding: 0
  margin: 0

.link-without-style
  text-decoration: none
  color: inherit

.visually-hidden
  position: absolute
  width: 1px
  height: 1px
  margin: -1px
  border: 0
  padding: 0
  white-space: nowrap
  clip-path: inset(100%)
  clip: rect(0 0 0 0)
  overflow: hidden

.scroll-thumb
  &::-webkit-scrollbar
    width: func.rem(6)
    padding: func.rem(12) 0
  &::-webkit-scrollbar-track
    background: none
    border-radius: func.rem(12)
  &::-webkit-scrollbar-thumb
    background: var(--secondary-color)
    border-radius: func.rem(12)
