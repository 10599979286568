@use 'src/styles/functions' as func
@import "themeVars"

.base-select
  .base-select__control
    background: var(--bg-color)
    border: 1px solid var(--border-color)
    border-radius: 5px
    transition: background-color 0.3s ease 0s
    font-size: func.rem(14)
    min-height: func.rem(40)
    padding-left: 20px
    &.base-select__control--menu-is-open
      border-bottom: none
      border-radius: func.rem(5) func.rem(5) 0 0
    &:hover
      background: var(--bg-hover-color)
    .base-select__value-container
      .base-select__single-value
        color: var(--placeholer-font-color)
      .base-select__placeholder
        margin-left: func.rem(20)
        color: var(--placeholer-font-color)
    .base-select__indicator
      margin-right: func.rem(10)
      svg
        color: #838999
        height: 25px
        width: 25px
  .base-select__menu
    background: var(--bg-color)
    border: 1px solid var(--border-color)
    border-top: none
    border-radius: 0 0 func.rem(5) func.rem(5)
    .base-select__option
      color: var(--text-color)
      padding: 8px 20px
      font-size: func.rem(14)
      border-top: 1px solid var(--select-menu-border-color)
      transition: background-color 0.3s ease 0s
      &:last-child
        border-radius: 0 0 func.rem(5) func.rem(5)
      &:hover
        background: var(--bg-hover-color)


  .base-select__menu
    .base-select__group
      .base-select__group-heading
        background: var(--bg-group-heading)
        padding: 5px 20px 5px 20px
        font-size: func.rem(14)
        .wrap-base-select__group--EUpo1
          color: #ffffffb0
          .base-select__group_options_length--IWFiC
            color: #000000
      .base-select__option
        &:last-child
          border-radius: initial
