html[data-theme='dark']
  --select-placeholder-bg: var(--admin-background-interactive-elements)
  --select-placeholder-color-font: var(--admin-color-font-interactive-elements)
  --select-hover-placeholder-bg: var(--admin-hover-background-interactive-elements)

  --select-disabled-color-font: var(--admin-color-font-interactive-elements)
  --select-disabled-option-bg: #90939b

  --select-border: #737373

html[data-theme='light']
  --select-placeholder-bg: var(--admin-background-interactive-elements)
  --select-placeholder-color-font: var(--admin-color-font-interactive-elements)
  --select-hover-placeholder-bg: var(--admin-hover-background-interactive-elements)

  --select-disabled-color-font: var(--admin-color-font-interactive-elements)
  --select-disabled-option-bg: #cecece

  --select-border: #737373