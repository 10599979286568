/*
  Как решить проблему с интеграцией в icoomoon:
    Копируем иконку в фигму;
    В слоях находим > Path;
    Копируем этот > Path;
    Применяем к нему outline stroke.

   Иконку может начать ломать после outline stroke.
   Для решения этой проблемы можно вытащить Group из слоев, экспортировать его и
   воспользоваться https://iconly.io/tools/svg-convert-stroke-to-fill для конвертации.
*/

%ic {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class*="icon-"] {
  &:before {
    @extend %ic;
    display: block;
  }
}

[class*="path"] {
  &:before {
    @extend %ic;
  }
}

.icon-assessed:before {
  content: "\e937";
  color: #777;
}

.icon-assesses:before {
  content: "\e938";
  color: #777;
}

.icon-revert:before {
  content: "\e900";
}

.icon-trash:before {
  content: "\e935";
}

.icon-relative-percent:before {
  content: "\e934";
}

.icon-average-score:before {
  content: "\e933";
}

.icon-type-factor-sctruct:before {
  content: "\e901";
}

.icon-zip:before {
  content: "\e930";
}

.icon-xlsx:before {
  content: "\e931";
}

.icon-file-downloads:before {
  content: "\e92f";
}

.icon-email-clock:before {
  content: "\e902";
}

.icon-notebook:before {
  content: "\e92d";
}

.icon-plus-square:before {
  content: "\e92b";
}

.icon-dash-square:before {
  content: "\e92c";
}

.icon-comment-fill:before {
  content: "\e92a";
}

.icon-comment-center-fill:before {
  content: "\e903";
}

.icon-comment-left:before {
  content: "\e926";
}

.icon-comment-right:before {
  content: "\e927";
}

.icon-comment-center:before {
  content: "\e928";
}

.icon-pdf:before {
  content: "\e925";
}

.icon-download:before {
  content: "\e924";
}

.icon-search:before {
  content: "\e923";
}

.icon-mini-arrow:before {
  content: "\e922";
}

.icon-star:before {
  content: "\e921";
  color: #fff;
}

.icon-hidden:before {
  content: "\e920";
  color: #838999;
}

.icon-menu:before {
  content: "\e91d";
  color: #838999;
}

.icon-incoming:before {
  content: "\e91e";
  color: #838999;
}

.icon-outgoing:before {
  content: "\e91f";
  color: #838999;
}

.icon-successful-noty:before {
  content: "\e91a";
  color: #838999;
}

.icon-info-noty:before {
  content: "\e91b";
  color: #838999;
}

.icon-error-noty:before {
  content: "\e91c";
  color: #838999;
}

.icon-stopwatch:before {
  content: "\e919";
  color: #838999;
}

.icon-link:before {
  content: "\e918";
  color: #838999;
}

.icon-projects:before {
  content: "\e917";
  color: #838999;
}

.icon-arrow:before {
  content: "\e914";
  color: #838999;
}

.icon-plus:before {
  content: "\e915";
  color: #838999;
}

.icon-minus:before {
  content: "\e916";
  color: #838999;
}

.icon-remove-light:before {
  content: "\e908";
  color: #838999;
}

.icon-remove-dark .path1:before {
  content: "\e909";
  color: rgb(131, 137, 153);
}

.icon-remove-dark .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(60, 63, 71);
}

.icon-remove-dark .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(60, 63, 71);
}

.icon-tooltip-light:before {
  content: "\e913";
  color: #838999;
}

.icon-tooltip-dark .path1:before {
  content: "\e906";
  color: rgb(131, 137, 153);
}

.icon-tooltip-dark .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(60, 63, 71);
}

.icon-warning .path1:before {
  content: "\e90c";
  color: rgb(131, 137, 153);
}

.icon-warning .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(60, 63, 71);
}

.icon-warning .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(60, 63, 71);
}

.icon-error .path1:before {
  content: "\e90f";
  color: rgb(131, 137, 153);
}

.icon-error .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(60, 63, 71);
}

.icon-error .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(60, 63, 71);
}

.icon-download-file:before {
  content: "\e912";
  color: #838999;
}

.icon-employee:before {
  content: "\e904";
  color: #838999;
}

.icon-pencil:before {
  content: "\e905";
  color: #838999;
}

.icon-gear:before {
  content: "\e929";
  color: #494c4e;
}

.icon-exit:before {
  content: "\e92e";
  color: #fff;
}

.icon-to_dark_theme:before {
  content: "\e932";
  color: #838999;
}

.icon-to_light_theme:before {
  content: "\e936";
  color: #838999;
}