html[data-theme='dark']
  --selection-list-item-color-font: var(--admin-color-font-interactive-elements)
  --selection-list-item-bg: var(--admin-background-interactive-elements)
  --selection-list-item-border: #40434B

  --selection-list-hover-option-bg: var(--admin-hover-background-interactive-elements)

html[data-theme='light']
  --selection-list-item-color-font: var(--admin-color-font-interactive-elements)
  --selection-list-item-bg: var(--admin-background-interactive-elements)
  --selection-list-item-border: #B6B6B8

  --selection-list-hover-option-bg: var(--admin-hover-background-interactive-elements)