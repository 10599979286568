html[data-theme='dark']
  --tab-bg: #35373D
  --tab-color: var(--primary-light-grey)
  --tab-active-color: var(--secondary-grey)
  --tab-active-bg: var(--content-block-bg)
  --tab-border-color: var(--primary-dark-grey)

html[data-theme='light']
  --tab-bg: #d2d2d2
  --tab-color: var(--primary-light-grey)
  --tab-active-color: inherit
  --tab-active-bg: #FFFFFF
  --tab-border-color: #F3F3F5