html[data-theme]
  // ADMIN PANEL VARIABLES
  // base colors
  --primary-dark-grey: #2D2D2D
  --primary-light-grey: #838999
  --pale-dark: #5a5a5a

  --primary-grey: #4E515C
  --secondary-grey: #CFD0D6
  --pale-grey: #e4e4e4

  // btn-toggle-superset
  --bg-not-enabled-btn-toggle-superset: #EB5760
  --bg-enabled-btn-toggle-superset: #3CCC76

  // font colors
  --admin-main-font-color: var(--primary-light-grey)
  --secondary-grey-text: #7F879A

  --dark-grey-line-color: #989BA4

  // admin-buttons
  --admin-btn-blue: #2F80ED

  // error-colors
  --error-color: #EB5760
  --hover-error-color: #d64851
