@use 'src/styles/mediaValues' as screen
@use 'src/styles/functions' as func

.header
  position: sticky
  width: 100%
  background: var(--main-bg)
  z-index: 1000
  top: 0
  transition: .5s
  margin-bottom: 50px
  @media screen and (max-width: screen.$tablet-screen)
    position: static
    margin-bottom: 10px
  .header-content
    position: relative
    display: flex
    max-width: 95%
    margin: 0 auto
    padding: 0 func.rem(15)
    align-items: center
    height: var(--header-height)
    @media screen and (max-width: screen.$large-phone-screen)
      min-height: 0
      justify-content: space-between
