html[data-theme='dark']
  --custom-radio-border-color: #FFFFFF
  --custom-radio-selected-bg: #FFFFFF
  --custom-radio-label-color-font: #FFFFFF
  --custom-radio-hover-color: var(--admin-color-font-hover-interactive-elements)
  --custom-radio-outline-color: var(--admin-outline-color)
  --custom-radio-disabled-color: #a3a3a3

html[data-theme='light']
  --custom-radio-border-color: #3C3F47
  --custom-radio-selected-bg: #3C3F47
  --custom-radio-label-color-font: var(--admin-color-font-interactive-elements)
  --custom-radio-hover-color: var(--admin-color-font-hover-interactive-elements)
  --custom-radio-outline-color: var(--admin-outline-color)
  --custom-radio-disabled-color: #7e7e7e