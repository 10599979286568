@use 'src/styles/functions' as func

.business-unit
  display: flex
  align-items: baseline
  position: relative
  border-radius: 12px
  padding: func.rem(4) func.rem(8)
  transition: background linear 300ms
  &:hover, &.with-card
    background: var(--admin-hover-background-interactive-elements)

.unit-button
  width: 100%