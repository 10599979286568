@import "_functions.sass"

html
  --header-height: #{rem(60)}
  --user-content-container-width: 95%
  --wrapper-user-container-side-paddings: 15px
  --progress-width: var(--user-content-container-width)
  --max-width-for-user-content-container: #{rem(1400)}
  --container-radius: 20px
  --notification-bar-top: calc(var(--header-height) - 20px)
  @media screen and (max-width: 768px)
    --user-content-container-width: 100%
    --wrapper-user-container-paddings: 0
    --wrapper-user-container-side-paddings: 15px
