html[data-theme='dark']
  --admin-checkbox-bg: var(--primary-grey)
  --admin-checkbox-color: #ffffff
  --admin-checkbox-hover-bg: #656873
  --admin-checkbox-checked-bd: #ffffff
  --admin-checkbox-checked-color: var(--primary-dark-grey)

html[data-theme='light']
  --admin-checkbox-bg: var(--pale-grey)
  --admin-checkbox-color: var(--primary-dark-grey)
  --admin-checkbox-hover-bg: #d0d0d0
  --admin-checkbox-checked-bd: var(--primary-grey)
  --admin-checkbox-checked-color: #ffffff