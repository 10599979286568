@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-Thin.woff2') format('woff2'),
    url('Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Thin';
    src:  url('Roboto-Thin.woff2') format('woff2'),
    url('Roboto-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-ThinItalic.woff2') format('woff2'),
    url('Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src:  url('Roboto-ThinItalic.woff2') format('woff2'),
    url('Roboto-ThinItalic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-Light.woff2') format('woff2'),
    url('Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src:  url('Roboto-Light.woff2') format('woff2'),
    url('Roboto-Light.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-LightItalic.woff2') format('woff2'),
    url('Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src:  url('Roboto-LightItalic.woff2') format('woff2'),
    url('Roboto-LightItalic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-Regular.woff2') format('woff2'),
    url('Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src:  url('Roboto-Regular.woff2') format('woff2'),
    url('Roboto-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-RegularItalic.woff2') format('woff2'),
    url('Roboto-RegularItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-RegularItalic';
    src:  url('Roboto-RegularItalic.woff2') format('woff2'),
    url('Roboto-RegularItalic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-Medium.woff2') format('woff2'),
    url('Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src:  url('Roboto-Medium.woff2') format('woff2'),
    url('Roboto-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-MediumItalic.woff2') format('woff2'),
    url('Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src:  url('Roboto-MediumItalic.woff2') format('woff2'),
    url('Roboto-MediumItalic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-Bold.woff2') format('woff2'),
    url('Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src:  url('Roboto-Bold.woff2') format('woff2'),
    url('Roboto-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    src:  url('Roboto-BoldItalic.woff2') format('woff2'),
    url('Roboto-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src:  url('Roboto-BoldItalic.woff2') format('woff2'),
    url('Roboto-BoldItalic.woff') format('woff');
}
