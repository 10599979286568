html[data-theme='dark']
  .base-select
    --bg-color: #565964
    --bg-hover-color: #676A73
    --border-color: #737373
    --select-menu-border-color: #40434B
    --text-color: #FFFFFF
    --bg-group-heading: #40434B
    --placeholer-font-color: var(--text-color)

html[data-theme='light']
  .base-select
    --bg-color: #F3F3F5
    --bg-hover-color: #E3E4E6
    --border-color: #737373
    --select-menu-border-color: #B6B6B8
    --text-color: #202020
    --bg-group-heading: #40434B
    --placeholer-font-color: var(--text-color)