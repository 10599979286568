html[data-theme='dark'] {
  --main-bg: #27282A;
  --content-block-bg: #3C3F47;
  --main-color: #fff;
  --answers-block-description-color: #CFD0D6;
  --start-description-color: #fff;
  --delimiter-color: #989BA4;
  --secondary-color: #989BA4;
  --grey-with-blue-color: #CFD0D6;
  --unit-tree-main-color: #a0a0a0;
  --units-tree-children-background: #484C56;
  --sna-tree-department-color: #B8B8B8;
  --sna-tree-line-color: #838999;
  --notify-bg: rgba(60, 63, 71, 0.1);
  --input-error-color: #F44A5F;

  /*select questions*/
  --select-question-default-bg: #484C56;
  --select-question-default-color: #838999;
  --select-question-delimer-color: #999ba3;

  --bonus-button-background: #484C56;

  /*block36*/
  --hover-unit-button: #484C56;
  --rated-unit-color: #838999;

  /*main-buttons*/
  --disable-button-border: #8f8f96;
  --disable-button-color: #8f8f96;

  /* return-code-input */
  --return-code-input-border: #838999;

  // ADMIN PANEL VARIABLES
  --admin-color-font-interactive-elements: #FFFFFF;
  --admin-color-font-hover-interactive-elements: #c4c4c4;
  --admin-background-interactive-elements: #565964;
  --admin-hover-background-interactive-elements: #676A73;

  // font colors
  --title-font: #ffffff;
  --font-active-color: #ffffff;
  --admin-hover-main-text: #ffffff;
  --light-grey-line-color: var(--primary-dark-grey);
  --link-in-text-block-color: var(--primary-light-grey);

  // admin-buttons
  --admin-btn-bg: var(--primary-grey);
  --admin-btn-color: var(--secondary-grey);
  --admin-btn-hover: #ffffff;
  --admin-btn-hover-color: var(--primary-light-grey);
  --admin-filter-projects-border: #676A73;

  --hover-grey-text: #ffffff;
  --hover-content-block-bg: #43464f;

  // outline
  --admin-outline-color: var(--primary-light-grey);


  --super-item-bg: #565962;
}